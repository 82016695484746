
$logoHoverColor: rgba(66, 139, 202, 0.3);
$logoActiveColor: rgba(66, 139, 202, 0.2);
$logoHoverBorder: 15px;

// Application Logo Internal Layout
.AppLogo {
    position: relative;
    display: grid;
    grid-column-gap: 30px;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;


    & .AppLogo--Wrapper {
        position: relative;
        grid-row: 1 / span 2;

        &:hover { 
            cursor: pointer;   

            &::after {
                border-radius: 5px;
                content: " ";
                position: absolute;
                display: block;
                left: -#{$logoHoverBorder};
                top: -#{$logoHoverBorder};
                width: calc(100% + 2 * #{$logoHoverBorder});
                height: calc(100% + 2 * #{$logoHoverBorder});
                background-color: $logoHoverColor;
            }
        }

        &:active {
            &::after {
                background-color: $logoActiveColor;
            }
        }

    }
}

.AppLogo--Communication {
    position: absolute;
    left: 310px;
    top: 100%;
    font-weight: bold;
    color: steelblue;
    font-size: 1.25rem;
    white-space: nowrap;

}

.AppLogo--top-title {
    font-size: 3rem;
    font-weight: bold;
}

.AppLogo--bottom-title {
    position: relative;
    font-size: 2rem;
    color: gray;
    font-weight: bold;
}

// Application Menu Button Styling
.AppMenuButton {
    background-color: #dedede;
    border-radius: 50%;
    padding: 0.85rem;
    border: 2px solid transparent;
    color: #333;

    &:hover {
        background-color: lightgray;        
    }

    &:active {
        border: 2px solid lighten(green, 10%);
    }
}

// Application Menu Layout
.AppMenu {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    align-items: center;
}

// Application Header Layout
.AppHeader {
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: 2rem;
    user-select: none;
}

.AppLogo--Image {
    width: auto;
    height: auto;
    max-height: 100px;
    max-width: 400px;
    align-self: center;
    justify-self: center;
}
