// Single Formik Field Layout
.Form--FormField--ErrorMessage--Wrapper {
    margin-top: calc(1rem / 20);
    position: relative;
    margin-bottom: calc(1rem / 2);
}

// Formik Error Message Style
.Form--FormField--ErrorMessage {
    position: absolute;
    left: 0;
    top: 1px;
    color: red;
    font-size:  1.05rem !important;
}

// Generic Form Field Input Style
.Form--FormField input {
    width: 100% !important;
    background: #eee;
 }

// Formik TextArea Style
.Form--FormField textarea {
    width: 100%;
    resize: none;
    margin-top: 0.25rem;
    background: #eee !important;
    padding: 0.5rem;    
}

.Form--FormField {
    display: inline-grid;
    width: 100%;
    grid-template-rows: auto auto;
}

// Voltage Formik Control
.FormikVoltageField {
    display: grid !important;
    grid-template-columns: 1fr auto !important;
}

// Dropdown Formik Component Style
.Form--FormField .p-dropdown {
    // margin-top: 0rem;
    background: linear-gradient(to bottom, white, #eee)  !important;
    color: black !important;
}

// File Upload Compoenent Style
.FormikFileUploadField {
    padding: 0.125rem 0rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    grid-gap: 0.25rem;
    justify-items: left;

    & > span {
        grid-column: 1 / span 2;
        font-style: italic;
    }
}

// Formik Picklist Component Style
.Formik-PickList {
    height: 100%;

    .p-picklist-list-wrapper  {
        display: grid;
        grid-template-rows: auto 1fr;
    }
}

.p-multiselect {
    background: linear-gradient(to bottom, white, #eee)  !important;
    color: black !important;
    
    .p-multiselect-token {
        line-height: 1.25rem !important;
        background: linear-gradient(to bottom, lightcyan, lightblue)  !important;
        border: 1px solid black;
        margin: 3px .25rem !important;
    }

}  

.p-dropdown {
    background: linear-gradient(to bottom, white, #eee)  !important;
    color: black !important;
}

.p-inputnumber {
    display: grid !important;
    grid-template-columns: 1fr auto !important;
}

.p-calendar {
    display: grid !important;
    grid-template-columns: 1fr auto !important;
}

.Form--FormField--Checkbox {
    display: inline-grid;
    grid-template-columns: auto auto 1fr;
    grid-gap: 0.5rem;
    cursor: pointer !important;
    user-select: none;

    &  label {
        font-weight: bold;
        cursor: pointer !important;
    }
}

.AvatarUpload {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
    width: 100%;
}
