
.AppFooter {
    padding: 1rem 2rem;
    color: black;
    font-size: 0.95rem;
    display: grid;
    grid-template-columns: 1fr repeat(3, auto);
    
    align-items: center;
    justify-items: right;


    & > span:nth-of-type(n + 2)::before {
        content: "|";
        margin: 0rem 0.5rem;
    }

    & a {
        color: black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
