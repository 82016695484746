
.ServiceLogDataList {
    margin: 0.5rem;
    box-shadow: 1px 1px 5px 1px gray !important;
    background-color: white;
    display: grid;
    grid-template-rows: auto auto 1fr;
    box-shadow: 1px 1px 5px 0px darkgray;
}

.ServiceLogDataList--Separator {
    height: 0.5rem;
}


.ServiceLogHeader {
    display: grid;
    grid-template-columns: auto 1fr auto;
    user-select: none;
    grid-gap: 1rem;
    font-weight: bold;
    font-size:  1.85rem;
    background: linear-gradient(to bottom, white, lightgray);
    padding: 1rem;
}

.ServiceLogDataList--Body {
    padding: 10px;
    height: 100%;
    display: grid;
}