
.ExceptionDialog-Footer {
    text-align: right;
}

.ExceptionDialog-Body {
    padding: 1rem;
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 20px;

    & span {
        font-weight: bold;
        font-size: 1.4rem;
    }

    & textarea {
        resize: none;
    }
}