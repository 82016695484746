
.EntityTreeView {
    position: relative;
}

.EntityTreeView--Content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    border: 1px solid #dee2e6;
}

.EntityTreeView--Toggler {
    font-size: 1.6rem;
    font-weight: bold;
    width: 1.4rem;
    height: 1.4rem;
    background-color: white;
    border: 1px solid black;
    display: grid;
    align-content: center;
    justify-content: center;
}

.Entity-Node {
    display: inline-grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
    font-weight: bold;
}

.Entity-Node--Checkbox {
    margin-right: 0.75rem;
}

.Entity-Node--Icon {
    display: inline-grid;
    margin-right: 0.5rem;
    width: 1.8rem;
    height: 1.8rem;
    line-height: 1;
    align-items: center;
    justify-items: center;

    font-weight: bold;
    border: 1px solid black;
    border-radius: 25%;
    box-shadow: 1px 1px 3px 0px gray;
}

.Entity-Node--Icon-Integrator {
    color: white;
    background-color: darken(goldenrod, 3%);
}

.Entity-Node--Icon-Distributor {
    background-color: darken(red, 10%);
    color: white;
}

.Entity-Node--Icon-EndCustomer {
    background-color: darken(green, 0%);
    color: white;
}

.Entity-Node--Icon-Unit {
    background-color: darken(blue, 10%);
    color: white;
}

.p-tree {
    border-width: 0px !important;
    padding: 0 !important;
}

.p-tree-header {
    position: sticky !important;
    left: 0;
    top: 0px;
    width: 100%;
    height: auto;
    background-color: whitesmoke;
    z-index: 10;
    margin-bottom: 20px;
    
    & input {
        border: 1px solid darkgray;
        box-shadow: none !important;
    }

}

.p-tree-container {
    padding: 0rem 2rem !important;
}

.p-treenode-content {
    box-shadow: none !important;
}

.p-tree .p-treenode-children {
    padding-left: 2rem !important;
}

.p-treenode-children {
    position: relative;
}

.p-treenode-children::before {
    content: "";
    position: absolute;
    left: 1rem;
    top: 0;
    bottom: calc(1rem + 1px);
    border-left: 1px dotted gray !important;
}

.p-treenode-content {
    position: relative;

    button {
        background-color: #ddd !important;
    }
}

.p-treenode-content::before {
    content: "";
    position: absolute;
    left: -1rem;
    top: 50%;
    width: 60px;
    border-top: 1px dotted gray !important;
}