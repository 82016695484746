
.HomeViewScreen {
    margin: 0.25rem;
    background-color: white;
    padding: 1rem;
    display: grid;
    grid-template-columns: calc(50% - 5px) 1fr;
    gap: 10px;
    box-shadow: 1px 1px 5px 0px darkgray;
}

.WelcomeInfo {
    display: grid;
    grid-template-rows: auto 1fr;
    box-shadow: 1px 1px 5px 0px darkgray;
}

.WelcomeInfo--Header {
    color: white;
    font-weight: bold;
    background-color: rgb(104, 159, 56);
    font-size: 2.5rem;
    padding: 10px 15px;
}

.WelcomeInfo--IntroText {
    border: 1px solid gray;
    padding: 10px 20px;
    font-size: 1.25rem;

}

.ManualsPanel {
    display: grid;
    grid-template-rows: auto 1fr;
    box-shadow: 1px 1px 5px 0px darkgray;  
}

.ManualsPanel--Header {
    color: white;
    font-weight: bold;
    background-color: rgb(104, 159, 56);
    font-size: 2.5rem;
    padding: 10px 15px;
}

.ManualsPanel--Body {
    border: 1px solid gray;
    position: relative;
}

.ManualsPanel--BodyContent {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 10px 20px;  
    overflow: auto;

    li {
        color: rgb(66, 139, 202);
    }
}

.ManualsPanel--Document {
    display: inline-grid;
    width: 100%;
    grid-template-columns: auto 1fr auto;

    color: rgb(66, 139, 202);
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;

    a {
        color: rgb(66, 139, 202);
        text-decoration: none;            
        &:hover  {
            text-decoration: underline;
        }
    }
}

.ManualsPanel--Document--Line {
    border-bottom: 1px dotted #444;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.ManualsPanel--DownloadLink {
    color: #444 !important;
}