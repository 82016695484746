
.LoadingProgress--Wrapper {
    position: relative;
}

.LoadingProgress {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    display: grid;
    align-items: center;
    justify-items: center;

    background-color: rgba(white, 0.5);
    color: rgba(black, 0.5);
}

.loading-progress-enter, .loading-progress-appear {
    opacity: 0;
}

.loading-progress-enter-active, .loading-progress-appear-active {
    opacity: 1;
    transition: opacity 300ms;
}

.loading-progress-enter-done, .loading-progress-appear-done {
    opacity: 1;
}


.loading-progress-exit {
    opacity: 1;
}

.loading-progress-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.loading-progress-exit-done {
    opacity: 0;
}