
.EditOrderForm--Body {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40rem;
    display: grid;
    grid-template-rows: repeat(5, auto);
    grid-gap: 1rem;
    border-radius: 1rem;
    padding: 1.5rem 3rem;
    border: 1px dashed gray;
}