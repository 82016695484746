
.EditUnitGroupForm-Body {
    display: grid;
    padding: 0.5rem;
    grid-template-columns: 35% 1fr;
    grid-gap: 1rem;
}

.EditUnitGroupForm-LeftPane {
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-gap: 1.5rem;
}

.EditUnitGroupForm-PickList {
    position: absolute;
    z-index: 1;
    left: calc(35% + 1.5rem);
    right: 0;
    top: 0.5rem;
    bottom: 4rem;
}

.EditUnitGroupForm-PickList {
    & .Form--FormField {
        height: 100%;
    }
}
