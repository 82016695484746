

.NotImplementedPage {
    display: grid;
    align-content: center;
    justify-content: center;
    color: darken(orangered, 10%);
    font-weight: bold;
    background-color: white;
    font-size: 3.5rem;
    & > span {
        text-align: center;
        display: inline-block;
        white-space: normal;
        padding-left: 100px;
        padding-right: 100px;
        padding-bottom: 10%
    }
}
