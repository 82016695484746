
// Animation Duraction
$fadeAnimationDuration: 250ms;
$scaleAnimationDuration: 250ms;

 // Helper  animation styles
////////////////////////////////////////////////////////////////////////

%GridStretch {
    display: grid;
    grid-template-rows: 1fr;
    justify-self: stretch;
    height: 100%; 
}

.ViewContainer {
  position: relative;
  @extend %GridStretch;
}

.ViewContainer > div {
    @extend %GridStretch;
}

%Animation-Common {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition-timing-function: ease-in-out;
  @extend %GridStretch;
}

 // Fading animation definition
////////////////////////////////////////////////////////////////////////

// Enter styles of fading animation
.View-Fade {
  @extend %Animation-Common;
}

.View-Fade-enter, .View-Fade-appear {
  @extend %Animation-Common;
  opacity: 0;
}

.View-Fade-enter-active, .View-Fade-appear-active {
  @extend %Animation-Common;
  opacity: 1;
  transition: opacity $fadeAnimationDuration;
}

.View-Fade-enter-done, .View-Fade-appear-done {
  @extend %Animation-Common;
  opacity: 1;
}

// Exit styles of fading animation
.View-Fade-exit {
  @extend %Animation-Common;
  opacity: 1;
}

.View-Fade-exit-active {
  @extend %Animation-Common;
  opacity: 0;
  transition: opacity $fadeAnimationDuration;
}

.View-Fade-exit-done {
  @extend %Animation-Common;
  opacity: 0;
}

 // Scaling animation definition
////////////////////////////////////////////////////////////////////////

// Enter styles of Scaling animation
.View-Scale, .View-Scale-enter, .View-Scale-appear {
  @extend %Animation-Common;
  opacity: 0;
  transform: scale(0, 0);
}

.View-Scale-enter-active, .View-Scale-appear-active {
  @extend %Animation-Common;
  opacity: 1;
  transform: scale(1, 1);
  transition: opacity $scaleAnimationDuration, transform $scaleAnimationDuration;
}

.View-Scale-enter-done, .View-Scale-appear-done {
  @extend %Animation-Common;
  opacity: 1;
  transform: scale(1, 1);
}

// Exit of the Scaling animation
.View-Scale-exit {
  @extend %Animation-Common;
  opacity: 1;
  transform: scale(1, 1);
}

.View-Scale-exit-active {
  @extend %Animation-Common;
  opacity: 0;
  transform: scale(0, 0);
  transition: opacity $scaleAnimationDuration, transform $scaleAnimationDuration;
}

.View-Scale-exit-done {
  @extend %Animation-Common;
  opacity: 0;
  transform: scale(0, 0);
}
