
.ImageColumn {
    box-shadow: 0px 0px 4px 0px gray;
    display: inline-block;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 3px;
    background: lightgray;
}

.ImageColumn--Image {
    display: block;
    width: auto;
    height: auto;
}

.ImageColumn--Loading-Progress {
    display: grid;
    align-content: center;
    justify-content: center;
}