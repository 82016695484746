
.TreeCheckBox {
    font-family: Arial, Helvetica, sans-serif;
    width: 1.6rem;
    height: 1.6rem;
    
    border: 1px solid black;
    border-radius: 3px;
    box-shadow: 1px 1px 2px 0px gray;

    font-weight: bold;
    font-size: 1.2rem;

    display: grid;
    align-content: center;
    justify-items: center;


    &:hover {
        cursor: pointer;
    }
}

.TreeCheckBox--Checked {
    color: black;
    background-color: darken(whitesmoke, 5%);
}

.TreeCheckBox--Neutral {
    background-color: darkgray;
}

.TreeCheckBox--Unchecked {
    background-color: white;
}