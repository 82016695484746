
.GenericContentForm--Wrapper {
    padding: 0.5rem !important;
    height: 100%;
}

.GenericContentForm {
    box-shadow: 1px 1px 5px 1px gray !important;
    height: 100%;
    display: grid !important;
    grid-template-rows: auto 1fr !important;

    & .p-card-content {
        height: 100%;
        padding: 0px !important;
    }
}

.GenericContentForm--Header {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    font-weight: bold;
    font-size:  1.85rem;
    background: linear-gradient(to bottom, white, lightgray);
    padding: 1rem;
}

.GenericContentForm--Content {
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
}

.GenericContentForm--Body {
    padding: 0.5rem;
    position: relative;

    & > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
}

.GenericContentForm--Footer {
    border-top: 1px solid gray;
    padding: 0.5rem;
    padding-top: 1rem;
}

.GenericContentForm--StandardButtons {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 0.5rem;
}
