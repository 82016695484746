
.EditUnitForm--Body {
    display: grid;
    grid-template-columns: 25% 50% 25%;
    padding: 0.5rem;

    & > div {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.EditUnitForm--BodyPart1 {
    display: grid;
    grid-template-rows: repeat(5, auto);
    grid-gap: 1rem;
}

.EditUnitForm--BodyPart2 {
    display: grid;
    grid-template-rows: repeat(3, auto) 1fr;
    grid-gap: 1rem;

    & > div:nth-child(1) {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 0.5rem / 2));
        align-items: center;
        grid-gap: 0.5rem;
    }

    & > div:nth-child(2) {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 0.5rem / 2));
        grid-template-rows: auto auto;
        grid-row-gap: 1rem;
        grid-column-gap: 0.5rem;
        align-items: center;

        & > *:nth-child(3) {
            grid-column: 1 / span 2;
        }
    }
}

.EditUnitForm--BodyPart3 {
    display: grid;
    grid-template-rows: repeat(3, auto) 1fr;
    grid-gap: 1rem;
}

.NoteWrapper {
    position: relative;
}

.NotePositioningWrapper {
    position: absolute;
    left: -100%;
    right: 0;
    top: 0;
    bottom: auto;
}