// Default font settings
body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

// Make the page height equal to browser window height
html, body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  font-size: 15px !important;
  overflow: hidden !important;
}

// Set the PrimeReact default font size
.p-widget {
  font-size: 1rem !important;
}

// Root application element, make height of the height of body element
#root {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  font-size: 15px !important;
  height: 100% !important;
  overflow: hidden !important;
}

// Prohibit text area resizing 
textarea {
  resize: none !important;
}

// For the slider reset the zoom - this component is not compatible with ZOOM settings unfortunately
.p-slider {
  zoom: var(--original-zoom) !important;
}

$componentFontSize: 20px;
$dataTableFontSize: $componentFontSize;

.p-button {
  font-size: $componentFontSize !important;

  &:not(.p-inputnumber-button) {
    height: 3rem !important;
  }

  &.p-button-icon-only {
    width: 3rem !important;
  }
}

.p-dropdown, .p-multiselect {
  font-size: $componentFontSize !important;
}

.p-inputtext {
  font-size: $componentFontSize !important;
}

.p-menu {
  font-size: $componentFontSize !important;
}

.p-datatable {
  & td {
    font-size: $dataTableFontSize !important;
  }
  & .ActionButtonList > button {
    font-size: calc(#{$dataTableFontSize} * 5 / 6) !important;
  }
}
