
// Volume Control Style
.VolumeControl {
    margin-top: 0.5rem;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    height: 3rem;
}

