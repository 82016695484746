
// Sound DataList Page Theme
.SoundDataList {
    margin: 0.25rem;
    background-color: white;
    padding: 1rem;
    display: grid;
    grid-template-rows: auto auto 1fr;
    box-shadow: 1px 1px 5px 0px darkgray;
}

// Sound DataList Header Layout
.SoundDataListHeader {
    display: grid;
    grid-template-columns: 250px auto 1fr auto;
    grid-gap: 3rem;
}

// Sound Header Separator
.SoundDataListHeader--Separator {
    height: 0.5rem;
}