
.PlaylistToggleButton {
    text-align: center;
    user-select: none;
    font-weight: bold;
    padding: 0.25rem;
    background: linear-gradient(to bottom, white, lightgray);
    border-radius: 0.5rem;
    box-shadow: 0px 0px 2px 0px black;

    &:hover {
        background: linear-gradient(to bottom, lightcyan, lightblue);
    }

    &:active {
        box-shadow: 0px 0px 3px 0px black;
    }

    &.PlaylistToggleButton--Highlight {
        background: linear-gradient(to bottom, lighten(red, 40%), lighten(red, 35%));
        box-shadow: 0px 0px 2px 1px rgba(red, 1);
    }
}
