$mainAppHeight: 500px;

// Application Wrapper Element style
.App {
  position: relative;
  background-color: #a6bbbb;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

// Application Content Style
.App--ContentWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: calc(100% - 40px);
  height: calc(100% - 50px);

  background-color: white; 
  box-sizing: border-box;
  border-radius: 0.5rem;

  box-shadow: 5px 5px 15px 5px #8c8c8c; 

  display: grid;
  grid-template-rows: auto 1fr auto;
  box-sizing: border-box;
}

// Application Body Layout
.App--Body {
  display: grid;
  grid-template-columns: auto 1fr;  
  box-sizing: border-box;
}

.App--ContentPageWrapper {
  overflow: hidden;
  position: relative; 
  background: whitesmoke;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  display: grid;
  justify-items: stretch;
}
