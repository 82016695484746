
.InProductionDataList--Header {
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: 60px;
    align-items: center;
    background-color: #ececec;
    padding: 10px
}

.InProductionDataList--Header--Quantities {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 15px;

    font-weight: bold;
    font-size: 1.25rem;
}

.InProductionDataList {
    margin: 0.25rem;
    background-color: white;
    padding: 1rem;
    display: grid;
    grid-template-rows: auto auto 1fr;
    box-shadow: 1px 1px 5px 0px darkgray;
}

.InProductionDataList--Separator {
    height: 0.5rem;
}