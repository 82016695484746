
.UploadSoundForm--Body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 20px);

    display: grid;
    grid-template-columns: 30% 1fr;
    grid-gap: 1rem;
}

.UploadSoundForm--Body--LeftPart {
    display: grid;
    grid-template-rows: auto 1fr;
}

.UploadSoundForm--Body--RightPart {
    display: grid;
    grid-template-rows: repeat(5, auto) 1fr;
    grid-gap: 1rem;
}
