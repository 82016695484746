// Layout of Logo 
.AppLogo--LoginForm {
    display: grid;
    grid-column-gap: 1rem;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;

    & img {
        grid-row: 1 / span 2;
    }
}

// Top Title Style
.AppLogo--LoginForm--TopTitle {
    font-size: 2.75rem;
    font-weight: bold;
}

// Bottom Title Style
.AppLogo--LoginForm--BottomTitle {
    font-size: 2rem;
    color: gray;
    font-weight: bold;
}

// Logo Separator Style
.AppLogo--LoginForm--Separator {
    margin: 2.75rem 0px  2rem 0px ;
    grid-column: 1 / span 2;
    width: 100%;
    border: 1px dashed lightgray;
}
