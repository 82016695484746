
.EditIntegrator--Body {
    padding: 10px;
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-gap: 10px;
}

.EditIntegrator--Body--Left-Part {
    display: grid;
    grid-gap: 1rem;
}
