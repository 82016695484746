
.PlaylistPickList {    
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 1fr 3rem;

    & .PlaylistPickList--ControlWrapper {
        height: 100%;
        position: relative;
        & > * {            
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100% !important;
        }

        & .p-picklist-list-wrapper {
            display: grid !important;
            grid-template-rows: auto 1fr !important;
        }
    }

    & .p-picklist-source-wrapper .p-picklist-header {
        padding: 0.5rem;
        height: 3.5rem;
    }

    & .p-picklist-target-wrapper .p-picklist-header {
        padding-top: 0.75rem;
        font-size: 1.5rem;
        font-weight: bold;
        height: 3.5rem;
    }

    & .TargetTemplate {
        display: grid;
        grid-template-columns: 80px 1fr;
        grid-gap: 1rem;
        align-items: center;
    }
}
