
.UnitDataList {
    margin: 0.25rem;
    background-color: white;
    padding: 1rem;
    display: grid;
    grid-template-rows: auto auto 1fr;
    box-shadow: 1px 1px 5px 0px darkgray;

    & .p-button-label {
        font-weight: bold !important;
    }


    // & .ActionButtonList {
    //     background: linear-gradient(white, whitesmoke);
    //     padding: 0.35rem;
    //     display: flex;
    //     flex-wrap: wrap;
    //     flex-direction: row;
    //     justify-content: center;
    //     border: 1px solid #ccc;
    //     border-radius: 0.5rem;
    //     box-sizing: border-box;
    //     box-shadow: 1px 1px 1px 0px rgba(#ccc, 0.5);

    //     .p-button {
    //         margin: 2px;
    //     }
    // }

    & td {
        padding: 0.35rem !important;
    }

    & td.zoom {
        position: relative;
        overflow: visible !important;
        vertical-align: middle;

        & > span {
            box-sizing: border-box;
            padding: 0.5rem;
            padding-left: 0.35rem;
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translate(0, -50%);
            min-width: 100%;
            width: max-content;
            height: auto;
            z-index: 100;
            line-height: 1;
            background: #e0eeee;
            box-shadow: 1px 1px 3px 1px black;
        }
    }

    & th, & td {
        border-right: 1px dotted darkgray !important;
    }
}

.UnitDataListHeader--Separator {
    height: 0.5rem;
}
