
.CupuType--FormBodyContainer {
    display: grid;
    grid-template-columns: auto;
    justify-items: center;
    height: 100%;        
}

.CupuType--FormBody {
    margin-top: 20px;
    border: 1px dotted gray;
    padding: 20px;
    width: 800px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 20px));
    gap: 40px;

    & > div {
        align-self: center;
        display: grid;
        gap: 30px;
    }
}
