
.PlaylistFormBody {
    display: grid;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    grid-template-columns: 60% 1fr;    
    grid-gap: 1rem;

    & h2 {
        margin: 0.5rem 0 1rem 0;
    }
}

.PlaylistFormBody--RightPart {
    padding: 5px;
    display: grid;
    grid-template-rows: repeat(3, auto) 1fr;
    gap: 5px;
}

.PlaylistForm--PicklistWrapper {
    display: grid;
}

.PlaylistForm--Schedules {
    display: grid;
    grid-template-rows: repeat(5, auto);
    grid-gap: 0.25rem;
    & label {
        font-size: 1.2rem;
        font-weight: bold;
    }
}

.PlaylistForm--Schedules--TimePeriod {
    display: grid;
    grid-template-columns: 35% 1fr;
    grid-gap: 1rem;
}

.PlaylistForm--FormFooter {
    display: grid;
    grid-template-columns: 1fr auto;
}

.PlaylistForm--FormFooter--Buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
}
