.UnitsFilter {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-gap: 0.25rem;
}

.UnitsFilter--FirstRow {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(2, calc(50% - 0.125rem / 2));
    grid-gap: 0.125rem;

    & > div {
        padding: 0.15rem;

        & > * {
            width: 100%;
        }
    }
}

.UnitsFilter--SecondRow {
    overflow: hidden;
    display: grid;
    grid-template-columns: 30% calc(70% -  2 * 0.125rem - 150px) 150px;
    grid-gap: 0.125rem;
    align-items: center;

    & > div {
        padding: 0.15rem;

        & > * {
            width: 100%;
        }
    }
}
