.PasswordRestForm--Footer {
    display: grid;
    grid-template-columns: auto 1fr auto;
    margin-top: 2rem;
}

.PasswordFormBody {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 290px;
}

.PasswordResetForm--Invalid {
    font-weight: bold;
    font-size: 25px;
    color: red;
    text-align: center;
    padding: 20px;
    border: 2px solid red;
    background: whitesmoke;
    margin-top: 24px;
    margin-bottom: 24px;
    border-radius: 10px;
}

.PasswordResetForm--Success {
    font-weight: bold;
    font-size: 25px;
    color: green;
    text-align: center;
    padding: 20px;
    border: 2px solid green;
    background: whitesmoke;
    margin-top: 24px;
    margin-bottom: 24px;
    border-radius: 10px;
}
