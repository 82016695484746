// Theme Parameters
$appBackground: #a6bbbb;

 // Layouting Styles
//////////////////////////////////////////////////////////

// Login Form Wrapper for centering the form inside browser window
.LoginForm--Wrapper {
    display: grid;
    height: 100%;
    background: $appBackground;
    align-items: center;
    justify-items: center;
}

// Form Layout Style
.LoginForm form {
    display: grid;
    grid-template-rows: repeat(4, auto);
}

// Layout of LoginForm Footer
.LoginForm--Footer {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
}

 // Form Style for LoginForm
//////////////////////////////////////////////////////////

// Login Form Styling
.LoginForm {
    width: 40rem;
    min-height: 42em;
    background: white;
    padding: 3rem;
    border-radius: 0.5rem;
    -webkit-box-shadow: 5px 5px 15px 5px #5b8c8c; 
    box-shadow: 5px 5px 15px 5px #8c8c8c;
}

//  Forgot-Password Link Style
.LoginForm--ForgotPassword {    
    font-size: 1.25rem !important;
    color: black;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

// Submit Button Style
.LoginForm--Submit {
    font-weight: bold !important;
    font-size: 1.25rem !important;
    padding: 0.8rem 1em !important;
}

 // Default-Form Style Overrides for Login Form
//////////////////////////////////////////////////////////

// Login Form Special Label Style
.LoginForm label {
    font-weight: bold;
    font-size: 1.35rem;
    margin-bottom: 0.5rem;
}

// Special Style for FormField in the LoginForm
.LoginForm .Form--FormField {
    margin-bottom: 2rem;
}

// Special Style for Input FormField in the LoginForm
.LoginForm input {
    width: 100% !important;
    background: whitesmoke;
    padding: 1rem 1.25rem;
    font-size: 1.125rem;
 }
 
 .InvalidCredentialsMessage {
     font-size: 1.25rem;
     font-weight: bold;
     color: red;
     text-align: center;
     margin-bottom: 1rem;
 }
