.OrderInProductionSettings {
    display: grid;    
    grid-template-columns: repeat(3, auto);
    grid-gap: 0.5rem;
    align-items: center;

    label {
        font-weight: bold;
    }
}
