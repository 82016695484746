
.SupportPage {
    margin: 0.25rem;
    background-color: white;
    padding: 1rem;
    display: grid;
    grid-template-rows: auto auto 1fr;
    box-shadow: 1px 1px 5px 0px darkgray;

    & a {
        color: #444;
        font-size: 1.1rem;
        font-weight: bold;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.SupportPage--Separator {
    height: 0.5rem;
}

.SupportPage--Header {
    display: grid;
    grid-template-columns: 300px 1fr auto;
}