
.CupuTypesDataList {
    margin: 0.25rem;
    background-color: white;
    padding: 1rem;
    display: grid;
    grid-template-rows: auto auto 1fr;
    box-shadow: 1px 1px 5px 0px darkgray;
}

.CupuTypesDataList--Header {
    display: grid;
    grid-template-columns: 300px 1fr auto;
}

.CupuTypesDataListHeader--Separator {
    height: 0.5rem;
}
