
.NoEntriesMessage-Container {
    display: grid;
    height: 100%;
}

.NoEntriesMessage {
    display: block;
    font-weight: bold;
    font-size: 450%;
    align-self: center;
    justify-self: center;
}
