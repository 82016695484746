
// SearchBox Icon Theme and positioning inside InputText component
.SearchBox--Icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    color: black;
}

.ClearBox--Icon {
    position: absolute;
    right: 2.35rem;
    top: 50%;
    opacity: 0.5;
    font-size: 125%;
    transform: translate(0, -50%);
    z-index: 1;
    color: black;

    &:hover {
        color: red;
    }
}

// Wrapper element for searchbox component - relative positioning for correct icon placement
.SearchBox--Wrapper {
    position: relative;
}

