// Layout of the sidebar application menu
.AppSidebarMenu {
    padding-right: 3rem;
    user-select: none;
    min-width: 330px;
}

.AppSidebarMenu--Header {
    padding: 1.25rem 2rem;
    font-size: 2rem;
    font-weight: bold;

    & > a {
        color: black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.AppSidebarMenu--SubMenu {
    background-color: #a6bbbb;
    color: white;
    padding: 2rem;
    padding-right: 5rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    & > ul {
        margin: 0;
        list-style-type: none;
    }
}

.AppSidebarMenu--SubMenuHeader {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;

    & > a {
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.AppSidebarMenu--SubMenu--MenuItem {
    font-size: 1.25rem;
    padding: 0.5rem 0rem;
   
    text-indent: -2.5rem;

    &::before {
        content: "-";
    }

    & a {
        margin-left: 20px;
        color: white;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
