
.UploadFirmware--FormBody {
    padding: 10px;
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-gap: 10px;
}

.UploadFirmware--Body--Left-Part {
    display: grid;
    gap: 1rem;
}

.UploadFirmware--Body--Right-Part {
    display: grid;
    grid-gap: 5rem;
    grid-template-rows: auto auto  1fr;
}