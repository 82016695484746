
.GenericDataList--Container {
    position: relative;
    display: block;
    height: 100%;
    border: 2px solid lightgray;
}

.GenericDataList {
    position: absolute;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    box-sizing: border-box;
}

.GenericDataList {
    & td {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }
}